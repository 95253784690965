import React, { useState, useEffect, useRef, useCallback } from 'react';
import './BotCaseTab.scss';
import HelperChat from '../../../../helper/HelperChat';
import { doToast, getItemFromArray, passInsertCase, generateElipsis } from '../../../../helper/HelperGeneral';
import RoomListNoChat from '../../roomListNoChat/RoomListNoChat';
import { connect } from 'react-redux';
// import { Scrollbars } from 'react-custom-scrollbars';
// import BotAgentIcon from "../../../../assets/img/roomlist/icon-other-agent.svg";
// import ChatbotIcon from "../../../../assets/img/roomlist/icon-chatbot-white.svg";
import { taptalk } from '@taptalk.io/web-sdk';
import CaseService from "../../../../services/chat/caseServices";
// import HelperDate from "../../../../helper/HelperDate";
import { VariableSizeList as List } from 'react-window';
import CaseListSortBy from '../../../reuseableComponent/caseListSortBy/CaseListSortBy';
import FilterBy from "../modalFilter/filterBy/FilterBy";
import { FiRefreshCw, FiSearch, FiRotateCcw, FiList } from 'react-icons/fi';
import { clearChangeContactInfo } from "../../../../redux/actions/reduxActionChangeUserAlias";
import { clearUpdateCaseLabel } from "../../../../redux/actions/reduxActionCaseLabel";
import { setCaseSortBy } from "../../../../redux/actions/reduxActionCaseSortBy";
import { clearStartConversation } from '../../../../redux/actions/reduxActionStartConversation';
import { clearStartConversationBa } from '../../../../redux/actions/reduxActionStartConversationBa';
import CaseListComponent from "../../roomListTab/caseListComponent/CaseListComponent";
import CaseListAction from "../../roomListTab/caseListAction/CaseListAction";
import { setActiveRoom } from '../../../../redux/actions/reduxActionActiveRoom';
import { setCaseListChecked } from "../../../../redux/actions/reduxActionCaseListChecked";
import { setReconnectRoomList } from "../../../../redux/actions/reduxActionReconnectRoomList";
import { clearCaseFilter } from "../../../../redux/actions/reduxActionCaseFilter";
import mixpanel from "mixpanel-browser";
import { CASE_TYPES } from '../../../../constants/inbox';
import iconFilterWhite from "../../../../assets/img/icon-filter-inbox-white.svg";
import iconFilterGrey from "../../../../assets/img/icon-filter-inbox-grey.svg";
import StartConversation from "../startConversation/StartConversation";

const DEFAULT_CELL_HEIGHT = 92;

const FILTER_MESSAGE = {
    date: "There are no cases for the selected dates. Please select different dates.",
    filter: "There are no cases for the selected filters. Please select different filter options.",
    all: "There are no cases for the selected filters and dates. Please select different filter options and dates."
}

const DEFAULT_FILTER_TOPIC_AGENT = {
    value: 0,
    label: ""
}

const INITIAL_OPEN_CASE_PARAM = (reconnect = false) => ({
    topicID: 0,
    agentAccountID: 0,
    assignmentStatus: "others",
    search: '',
    lastID: 0,
    isSearchOrFilter: false,
    isReconnect: reconnect,
    isLoadMoreBot: false,
    label: [],
    junkStatus: ""
});

// var style = {
//     scrollStyle: {
//       position: "relative",
//       backgroundColor: "#ff7d00",
//       right: "-5px",
//       width: "3px",
//       borderRadius: "8px"
//     }
// };

const CONNECTING_STATUS = {
    disconnected: 1,
    loading: 2,
    connected: 3
};

const SORT_MESSAGE_BY = {
    last_message: "last_message",
    created_time: "created_time"
}

const MESSAGE_TYPE = {
    CASE_CREATED: 3005,
    CASE_CLOSED: 3001,
    CASE_REOPEN: 3002,
    CASE_RATING_BUTTON: 3003,
    CASE_RATING_BUTTON_DISABLED: 3004,
    CASE_UPDATE: 3006,
    TYPE_3007: 3007
}

// const PREFIX_CASE = 'case#';

let ListBotCaseComponent = (props) => {
    let {
        caseLabel, dataLength, containerHeight,
        row, mainProps,
        fetchingBotCaseIsErrorProps, fetchingBotCaseProps,
        onClickRetryMoreCaseBotProps, onScrollRoomListListenerProps,
        isLastCaseHavingMarginBottomProps, refProps,
        needReplyData
    } = props;

    let arrayOfRowHeight = [];

    let generateRowHeight = () => {
        arrayOfRowHeight = [];

        Object.keys(props.case).map((value, index) => {
            if (props.case[value].labelIDs && props.case[value].labelIDs.length > 0 && caseLabel.length > 0) {
                // arrayOfRowHeight.push(183);
                arrayOfRowHeight.push(154)
            } else {
                // arrayOfRowHeight.push(156);
                arrayOfRowHeight.push(127);
            }

            return null;
        })
    }

    generateRowHeight();

    const getItemSize = (index) => {
        return arrayOfRowHeight[index];
    }

    let arrayOfLabel = []
    const generateLabelCase = () => {
        Object.keys(props.case).map((value, index) => {
            if (props.case[value].labelIDs && caseLabel) {
                if (props.case[value].labelIDs.length > 0) {
                    if (caseLabel.length > 0) {
                        const labels = caseLabel.filter((el) => {
                            return props.case[value].labelIDs.find(val => val === el.id)
                        });

                        const labelCase = [...labels]
                        // console.log('labelCase', labelCase)
                        arrayOfLabel.push([...Array(labelCase.length).fill(false)])
                        // arrayOfLabel.push(labelCase)
                        // setHiddenUnassigned(labelCase)
                    }
                }
            }
            return null
        })
    }
    generateLabelCase()

    return (
        // ${needReplyProps > 0 ? "need-reply-show" : ""} 
        <List
            className={`List all-case-list list-bot-case  
                        ${!isLastCaseHavingMarginBottomProps ? "last-case-no-margin-bottom" : ""}
            `}
            height={containerHeight}
            itemCount={dataLength}
            itemSize={getItemSize}
            width={"100%"}
            onScroll={onScrollRoomListListenerProps}
            ref={refProps}
            itemData={{
                caseData: props.case,
                caseLabel: caseLabel,
                labelHidden: arrayOfLabel,
                mainProps: mainProps,
                _fetchingBotCaseIsErrorProps: fetchingBotCaseIsErrorProps,
                _fetchingBotCaseProps: fetchingBotCaseProps,
                _onClickRetryMoreBotProps: onClickRetryMoreCaseBotProps,
                _refProps: refProps,
                _needReplyData: needReplyData,
                _dataLength: dataLength
            }}
        >
            {row}
        </List>
    )
};

const getLabelCase = (labelIDs, labelList) => {
    if (labelIDs && labelList) {
        if (labelIDs.length > 0) {
            if (labelList.length > 0) {
                let newLabel = [];

                labelIDs.map((v) => {
                    if (getItemFromArray(labelList, "id", v)) {
                        newLabel.push(getItemFromArray(labelList, "id", v))
                    } else {
                        newLabel.push({ name: "", id: "" })
                    }
                    return null;
                })

                const labelCase = [...newLabel];
                return labelCase;
            }
        }
    }
    return []
}

const LabelItem = ({ label, container, index, isItemHidden, setIsItemHidden }) => {
    const itemRef = useRef();
    // console.log(index, isItemHidden)

    // useEffect(() => {
    //     if (!container.current) return;

    //     const { offsetLeft, offsetWidth } = itemRef.current;

    //     if (offsetLeft + offsetWidth > container.current.offsetWidth) {

    //         const copyIsItemHidden = [...isItemHidden];
    //         copyIsItemHidden[index] = true;
    //         setIsItemHidden(copyIsItemHidden);
    //     }
    // }, [index, container, isItemHidden, setIsItemHidden]);

    return (
        <div key={`label-${label.id}`} className="case-tab-label-content" style={{ background: label.backgroundColor, display: isItemHidden[index] ? "none" : "inline-block" }} ref={itemRef} >
            <b>{generateElipsis(label.name, 13)}</b>
        </div>
    );
};

let printRowBotCaseList = ({ index, style, data }) => {
    let myAgentAccountID = data.mainProps.myAgentData.account.id;
    let caseDetail = data.caseData[Object.keys(data.caseData)[index]];
    let message = data.caseData[Object.keys(data.caseData)[index]].tapTalkRoom;
    let _props = data.mainProps;
    let _needReplyData = data._needReplyData;

    let onClickBotCaseRoom = (room, caseData) => {
        _props.clearStartConversation();
        _props.clearStartConversationBa();
        _props.onClickRoomListProps(room, caseData)
    }

    const containerRef = useRef()

    const labelCase = getLabelCase(caseDetail.labelIDs, data.caseLabel)

    const hidden = data.labelHidden[index] ? data.labelHidden[index] : []

    const [isItemHidden, setIsItemHidden] = useState([...hidden])

    // useEffect(() => {
    //     setIsItemHidden(hidden)
    // }, [data.labelHidden])

    // const totalHidden = () => {
    //     let totalHiddenItem = 0;
    //     if (isItemHidden) {
    //         for (let hideItem of isItemHidden) {
    //             if (hideItem === true) totalHiddenItem++;
    //         }
    //     }
    //     return totalHiddenItem;
    // };

    return (
        <div style={style}>
            <CaseListComponent
                caseDetail={caseDetail}
                roomType={"chatbot"}
                message={message}
                myAgentAccountID={myAgentAccountID}
                labelCase={labelCase}
                // totalHidden={totalHidden()}
                _containerRef={containerRef}
                LabelItem={LabelItem}
                isItemHidden={isItemHidden}
                setIsItemHidden={setIsItemHidden}
                onClickCase={onClickBotCaseRoom}
                checkedType="bot"
                allCase={data.caseData}
                needReplyData={_needReplyData}
                hideCaseStatus
            />

            {(index === (data._dataLength - 1)) &&
                <React.Fragment>
                    <div className="fetch-solved-case">
                        {data._fetchingBotCaseProps &&
                            <div className="fetch-solved-case-content">
                                <div className="lds-ring">
                                    <div /><div /><div /><div />
                                </div>

                                <b>More Cases</b>
                            </div>
                        }

                        {data._fetchingBotCaseIsErrorProps &&
                            <div className="fetch-solved-case-content no-fill-button retry-fetch"
                                onClick={() => data._onClickRetryMoreCaseProps()}
                            >
                                <FiRefreshCw />

                                <b>Retry Fetch</b>
                            </div>
                        }
                    </div>

                    {/* {data._fetchingBotCaseIsErrorProps &&
                        <div className="fetch-solved-case">
                            <div className="fetch-solved-case-content no-fill-button retry-fetch"
                                onClick={() => data._onClickRetryMoreCaseProps()}
                            >
                                <FiRefreshCw />

                                <b>Retry Fetch</b>
                            </div>
                        </div>
                    } */}

                </React.Fragment>
            }
        </div>
    )
}

var BotCaseTab = (props) => {
    let [isRoomListLoadingFinish, setIsRoomListLoadingFinish] = useState(false);
    let [chatDataFiltered, setChatDataFiltered] = useState({});

    // let [otherCaseList, setBotCaseList] = useState({});

    let [hashMapTypingTimeout, setHashMapTypingTimeout] = useState({});

    let [panelBotCaseHeight, setPanelBotCaseHeight] = useState(0);

    let [needReplyDataBot, setNeedReplyDataBot] = useState({});

    // let [otherCaseSortBy, setBotCaseSortBy] = useState(SORT_MESSAGE_BY.last_message);
    let [isshowBotCaseSortBy, setIsshowBotCaseSortBy] = useState(false);

    let [noMoreBotCase, setNoMoreBotCase] = useState(false);

    let [fetchingBotCase, setFetchingBotCase] = useState(false);
    let [fetchingBotCaseIsError, setFetchingBotCaseIsError] = useState(false);

    let [filterSearchValue, setFilterSearchValue] = useState('');
    let [isLoadingSearchBotCaseFinish, setIsLoadingSearchBotCaseFinish] = useState(true);

    //filter
    let [filterTopicValue, setFilterTopicValue] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValue, setFilterAgentValue] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValue, setFilterMediumValue] = useState([]);
    let [filterCaseType, setFilterCaseType] = useState(CASE_TYPES.allTypes.value);

    let [filterTopicValueBefore, setFilterTopicValueBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValueBefore, setFilterAgentValueBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValueBefore, setFilterMediumValueBefore] = useState([]);
    let [filterCaseTypeBefore, setFilterCaseTypeBefore] = useState(CASE_TYPES.allTypes.value);

    let [searchNotFound, setSearchNotFound] = useState(false);
    let [isSearchActive, setIsSearchActive] = useState(false);
    let [showModalFilterTopicAgent, setShowModalFilterTopicAgent] = useState(false);
    let [topicList, setTopicList] = useState([]);
    let [agentList, setAgentList] = useState(null);
    let [isResetFilter, setIsResetFilter] = useState(false);
    let [isFilterTopicAgentChannelActive, setIsFilterTopicAgentChannelActive] = useState(false);
    let [needReplyFilterVal, setNeedReplyFilterVal] = useState(false);
    let [isShowModalStartConversation, setIsShowModalStartConversation] = useState(false);
    let [filterLabelValue, setFilterLabelValue] = useState([])
    let [filterLabelValueBefore, setFilterLabelValueBefore] = useState([])

    let [lastBulkJunk, setLastBulkJunk] = useState({
        time: false,
        arrayOfRoomIDs: []
    })

    let [lastBulkSolve, setLastBulkSolve] = useState({
        time: false,
        arrayOfRoomIDs: []
    })

    let [queueEmitMessage, setQueueEmitMessage] = useState({
        lastRun: false,
        q: []
    });

    useEffect(() => {
        if (queueEmitMessage.q.length > 0) {
            let _q = { ...queueEmitMessage };

            if (_q.lastRun === false) {
                _q.lastRun = new Date().valueOf();

                if (_q.q[0].type === "new") {
                    runNewMessageAction(_q.q[0].message, () => {
                        _q.q.shift();

                        _q.lastRun = false;

                        setQueueEmitMessage(_q);
                    });
                } else {
                    runUpdateMessageAction(_q.q[0].message, () => {
                        _q.q.shift();

                        _q.lastRun = false;

                        setQueueEmitMessage(_q)
                    });
                }
            }
        }
    }, [queueEmitMessage])

    let toggleModalStartConversation = (show) => {
        setIsShowModalStartConversation(show);
    }

    //bulk
    useEffect(() => {
        if (lastBulkJunk.time) {
            actionJunkBulk(lastBulkJunk.arrayOfRoomIDs.slice())
        }
    }, [lastBulkJunk])

    useEffect(() => {
        if (lastBulkSolve.time) {
            actionRemoveFromRoomListBulk(lastBulkSolve.arrayOfRoomIDs.slice())
        }
    }, [lastBulkSolve])
    //bulk

    //reconnect
    useEffect(() => {
        let _reconRoomList = props.reconnectRoomList;

        if (_reconRoomList && _reconRoomList > 0) {
            getBotCaseList(INITIAL_OPEN_CASE_PARAM(true));

            props.setReconnectRoomList(0);
        }
    }, [props.reconnectRoomList])
    //reconnect

    useEffect(() => {
        let _changeContactInfo = props.changeContactInfo;
        let _chatDataFiltered = { ...chatDataFiltered };

        if (_changeContactInfo.state) {
            let changeChatFilteredBot = () => {
                Object.keys(_chatDataFiltered).map((value, index) => {
                    if (_chatDataFiltered[value].userID === _changeContactInfo.userID) {
                        _chatDataFiltered[value].userAlias = _changeContactInfo.alias;
                        _chatDataFiltered[value].userFullName = _changeContactInfo.fullname;
                        _chatDataFiltered[value].userPhone = _changeContactInfo.phone;
                        _chatDataFiltered[value].userEmail = _changeContactInfo.email;
                    }

                    return null;
                })

                setChatDataFiltered(_chatDataFiltered);
            }

            changeChatFilteredBot();
            props.clearChangeContactInfo();
        }
    }, [props.changeContactInfo])

    useEffect(() => {
        let isRunUpdateCaseLabelProps = props.updateCaseLabel;
        let _chatDataFiltered = { ...chatDataFiltered };

        if (isRunUpdateCaseLabelProps.state) {
            const roomID = isRunUpdateCaseLabelProps.roomID.roomID;

            if (_chatDataFiltered[roomID]) {
                if (_chatDataFiltered[roomID].id === isRunUpdateCaseLabelProps.id) {
                    if (isRunUpdateCaseLabelProps.action === 'add') {
                        _chatDataFiltered[roomID].labelIDs = [..._chatDataFiltered[roomID].labelIDs, ...isRunUpdateCaseLabelProps.labelIDs];
                    } else if (isRunUpdateCaseLabelProps.action === 'remove') {
                        const removeLabelIDs = [...isRunUpdateCaseLabelProps.labelIDs]
                        const currentLabelIDs = [..._chatDataFiltered[roomID].labelIDs]
                        const newLabelIDs = currentLabelIDs.filter(labelID => {
                            return !removeLabelIDs.find(val => val === labelID)
                        })
                        _chatDataFiltered[roomID].labelIDs = [...newLabelIDs]
                    }
                }
                setChatDataFiltered(_chatDataFiltered);
            }
            props.clearUpdateCaseLabel();
        }

    }, [props.updateCaseLabel])

    let otherCaseReff = useCallback(node => {
        if (node !== null) {
            node.resetAfterIndex(0);
        }
    }, [chatDataFiltered]);

    let onScrollRoomListListener = () => {
        let el = document.getElementsByClassName("list-bot-case")[0];
        if (el && !fetchingBotCase && !fetchingBotCaseIsError) {
            if ((el.scrollHeight - el.scrollTop - DEFAULT_CELL_HEIGHT) <= el.clientHeight) {
                runMoreBotCase();
            }
        }
    };

    let onClickRetryMoreCase = () => {
        runMoreBotCase();
    };

    let runMoreBotCase = () => {
        if (noMoreBotCase && Object.keys(chatDataFiltered).length > 0) {
            setFetchingBotCase(true);

            let _chatDataFiltered = { ...chatDataFiltered };

            getBotCaseList({
                topicID: filterTopicValue.value === 0 ? 0 : filterTopicValue.value,
                agentAccountID: filterAgentValue.value === 0 ? 0 : filterAgentValue.value,
                mediums: filterMediumValue,
                search: filterSearchValue,
                lastID: _chatDataFiltered[Object.keys(_chatDataFiltered)[Object.keys(_chatDataFiltered).length - 1]].id,
                isSearchOrFilter: false,
                isReconnect: false,
                isLoadMoreBot: true,
                label: filterLabelValue,
                junkStatus: filterCaseType
            })
        }
    }

    let actionSetPanelCaseBotHeight = (activeSelect) => {
        let elBotCaseTabWrapper = document.querySelectorAll(".bot-case-tab-wrapper")[0];

        if (elBotCaseTabWrapper) {
            // let defaultHeight = elBotCaseTabWrapper.offsetHeight - 47;
            // let defaultHeight = elBotCaseTabWrapper.offsetHeight - 89;
            let defaultHeight = 0;

            if (activeSelect) {
                defaultHeight = elBotCaseTabWrapper.offsetHeight - 63;
            } else {
                defaultHeight = elBotCaseTabWrapper.offsetHeight - 37;
            }
            setPanelBotCaseHeight(defaultHeight);
        }
    }

    useEffect(() => {
        let _connectStatus = props.connectingStatusProps;
        let elBotCaseTabWrapper = document.querySelectorAll(".bot-case-tab-wrapper")[0];

        if (elBotCaseTabWrapper) {
            if (_connectStatus === CONNECTING_STATUS.disconnected || _connectStatus === CONNECTING_STATUS.loading) {
                // elBotCaseTabWrapper.style.height = "calc(100vh - 135px)";
                elBotCaseTabWrapper.style.height = "calc(100vh - 79px)";
            } else {
                // elBotCaseTabWrapper.style.height = "calc(100vh - 109px)";
                elBotCaseTabWrapper.style.height = "calc(100vh - 53px)";
            }

            actionSetPanelCaseBotHeight(props.caseListChecked["bot"].isShow);
        }

    }, [props.connectingStatusProps])

    useEffect(() => {
        window.addEventListener("resize", () => {
            actionSetPanelCaseBotHeight(props.caseListChecked["bot"].isShow);
        })

        actionSetPanelCaseBotHeight(props.caseListChecked["bot"].isShow);
    }, [])

    let removeFromNeedReply = (roomID) => {
        let _needReplyData = { ...needReplyDataBot };

        if (_needReplyData[roomID]) {
            delete _needReplyData[roomID];
            setNeedReplyDataBot(_needReplyData);
        }
    }

    let addNeedReplyCounter = (data) => {
        if (data !== "" && !data.isJunk) {
            let _needReplyData = { ...needReplyDataBot };

            if (data.tapTalkRoom.lastMessage.user.userRole.code !== "chatbot") {
                _needReplyData[data.tapTalkRoom.lastMessage.room.roomID] = data;

                if (props.needReplyColorIndicator) {
                    _needReplyData[data.tapTalkRoom.lastMessage.room.roomID] = HelperChat.needReplyLevelCount(props.needReplyColorIndicator, _needReplyData[data.tapTalkRoom.lastMessage.room.roomID]);
                }

                setNeedReplyDataBot(_needReplyData);
            }
        }
    }

    let removeFromNeedReplyBulk = (roomIDs) => {
        let _needReplyData = { ...needReplyDataBot };

        roomIDs.map((v) => {
            if (_needReplyData[v]) {
                delete _needReplyData[v];
            }

            return null;
        })
        setNeedReplyDataBot(_needReplyData);
    }

    let actionRemoveFromRoomListBulk = (roomIDs) => {
        let _data = { ...chatDataFiltered };

        removeFromNeedReplyBulk(roomIDs);

        roomIDs.map((v) => {
            if (_data[v]) {
                delete _data[v];
            }

            return null;
        })

        setChatDataFiltered(_data);
    }

    let actionJunkBulk = (roomIDs) => {
        let _data = { ...chatDataFiltered };

        roomIDs.map((v) => {
            if (_data[v]) {
                _data[v].isJunk = true;
            }

            return null;
        })

        setChatDataFiltered(_data);
        setLastBulkJunk({
            time: false,
            arrayOfRoomIDs: []
        });
    }

    let actionUpdateRoomList = (message, isUpdate = false) => {
        let _chatDataFiltered = { ...chatDataFiltered };
        let specialCheck = () => {
            let p = false;

            if ((
                message.type === MESSAGE_TYPE.CASE_CLOSED ||
                message.type === MESSAGE_TYPE.CASE_CREATED ||
                message.type === MESSAGE_TYPE.CASE_RATING_BUTTON ||
                message.type === MESSAGE_TYPE.CASE_RATING_BUTTON_DISABLED ||
                message.type === MESSAGE_TYPE.CASE_REOPEN ||
                message.type === MESSAGE_TYPE.CASE_UPDATE ||
                message.type === MESSAGE_TYPE.TYPE_3007
            ) && message.isRead) {
                p = true;
            }

            return p;
        }

        let isEmitWasNotCaseDetailUpdate = (messageType) => {
            return (
                messageType !== MESSAGE_TYPE.CASE_CREATED &&
                messageType !== MESSAGE_TYPE.TYPE_3007 &&
                messageType !== MESSAGE_TYPE.CASE_UPDATE &&
                messageType !== MESSAGE_TYPE.CASE_CLOSED &&
                messageType !== MESSAGE_TYPE.CASE_RATING_BUTTON &&
                messageType !== MESSAGE_TYPE.CASE_RATING_BUTTON_DISABLED &&
                messageType !== MESSAGE_TYPE.CASE_REOPEN
            )
        }

        let actionRemoveFromRoomList = () => {
            let _data = { ...chatDataFiltered };

            if (_data[message.room.roomID]) {
                delete _data[message.room.roomID];

                if (Object.keys(_data).length === 0) {
                    setChatDataFiltered({});
                } else {
                    setChatDataFiltered(_data);
                }
            }
        }

        let isMessageInCaseChatbot = (message) => {
            return _chatDataFiltered[message.room.roomID];
        }

        //update last message and unread
        if (isEmitWasNotCaseDetailUpdate(message.type)) {
            let sortBy = props.caseSortBy.other;

            if (isMessageInCaseChatbot(message)) {
                HelperChat.setRoomListLastMessage(message, _chatDataFiltered, sortBy, isUpdate, (newHashmapOfMessages) => {
                    setChatDataFiltered(newHashmapOfMessages);

                    // //remove from need reply
                    if (!isUpdate) {
                        removeFromNeedReply(message.room.roomID);
                    } else {
                        addNeedReplyCounter(newHashmapOfMessages[message.room.roomID]);
                    }
                });
            }
        } else {
            //insert new room to room list
            let _chatDataFiltered = { ...chatDataFiltered };

            if (
                (message.type === MESSAGE_TYPE.CASE_CREATED && !isUpdate) &&
                !_chatDataFiltered[message.room.roomID] &&
                message.data.assigneeType !== "agent" &&
                passInsertCase(props.caseFilter, "bot", message.data)
            ) {
                // if ((message.type === MESSAGE_TYPE.CASE_UPDATE && !isUpdate) && !_chatDataFiltered[message.room.roomID]) {
                // if (props.topicListProps[message.data.topicID]) {
                let generateNewBotCaseObject = () => {
                    let newBotCase = {};

                    newBotCase[message.room.roomID] = message.data;
                    newBotCase[message.room.roomID].tapTalkRoom = {};
                    newBotCase[message.room.roomID].tapTalkRoom.lastMessage = message;
                    newBotCase[message.room.roomID].tapTalkRoom.unreadCount = 0;

                    return newBotCase;
                }

                if (Object.keys(_chatDataFiltered).length === 0) {
                    setChatDataFiltered(generateNewBotCaseObject());
                } else {
                    setChatDataFiltered(Object.assign(generateNewBotCaseObject(), _chatDataFiltered));
                }

                addNeedReplyCounter(generateNewBotCaseObject()[message.room.roomID]);
                // }
            }
            // insert new room to room list

            //change case-status-agent-badge
            if (Object.keys(_chatDataFiltered).length > 0 && !specialCheck()) {
                // if ((message.type === MESSAGE_TYPE.CASE_UPDATE || message.type === MESSAGE_TYPE.TYPE_3007) && _chatDataFiltered[message.room.roomID]) {
                if ((message.type === MESSAGE_TYPE.CASE_UPDATE) && _chatDataFiltered[message.room.roomID]) {
                    // let currentTaptalkRoom = {..._chatDataFiltered[message.room.roomID].tapTalkRoom};
                    // _chatDataFiltered[message.room.roomID] = {...message.data};

                    // if (!message.isHidden) {
                    //     _chatDataFiltered[message.room.roomID].tapTalkRoom = {};
                    //     _chatDataFiltered[message.room.roomID].tapTalkRoom.lastMessage = message;
                    //     _chatDataFiltered[message.room.roomID].tapTalkRoom.unreadCount = currentTaptalkRoom.unreadCount;
                    // } else {
                    //     _chatDataFiltered[message.room.roomID].tapTalkRoom = currentTaptalkRoom;
                    // }

                    // setChatDataFiltered(_chatDataFiltered);
                    removeFromNeedReply(message.room.roomID);
                    actionRemoveFromRoomList();
                }

                if (message.type === MESSAGE_TYPE.TYPE_3007) {
                    if (needReplyDataBot[message.room.roomID]) {
                        removeFromNeedReply(message.room.roomID);
                    }
                }
            }
            //change case-status-agent-badge

            //remove from chat data filtered if case closed
            if (props.activeCasePanel !== 'solved' && _chatDataFiltered[message.room.roomID] && !specialCheck()) {
                if (message.type === MESSAGE_TYPE.CASE_CLOSED) {
                    removeFromNeedReply(message.room.roomID);
                    actionRemoveFromRoomList();
                }
            }
            //remove from chat data filtered if case closed

            //remove from chat data filtered if topic id is not valid
            if (message.data !== "" && !specialCheck()) {
                if (!props.topicListProps[message.data.topicID] && message.data.topicID !== 0) {
                    removeFromNeedReply(message.room.roomID);
                    actionRemoveFromRoomList();


                    if (props.activeRoom && (props.activeRoom.xcRoomID === message.data.tapTalkXCRoomID)) {
                        props.setActiveRoom(null);
                    }
                }
            }
            //remove from chat data filtered if topic id is not valid
        }

        // setTimeout(function() {
        //     getUpdatedRoomList({
        //         onSuccessGetRoomList: (roomListsData) => {
        //             //insert new room to room list
        //             let _chatDataFiltered = chatDataFiltered === Object.keys(chatDataFiltered).length < 1 ? {} : {...chatDataFiltered};
        //             let _otherCaseList = {...otherCaseList};

        //             if(roomListsData[message.room.roomID]) {
        //                 if((message.type === MESSAGE_TYPE.CASE_UPDATE && !isUpdate) && !_otherCaseList[PREFIX_CASE + message.data.id]) {
        //                     if(props.topicListProps[message.data.topicID]) {
        //                         let newBotCase = null;

        //                         if(Object.keys(_otherCaseList).length === 0) {
        //                             newBotCase = {[PREFIX_CASE + message.data.id] : message.data};
        //                         }else {
        //                             newBotCase = Object.assign({[PREFIX_CASE + message.data.id] : message.data}, _otherCaseList);
        //                         }

        //                         setBotCaseList(newBotCase);
        //                     }
        //                 }
        //             }
        //             //insert new room to room list

        //         }
        //     });
        // }, 1000);

    }

    // let getUpdatedRoomList = (callback = null) => {
    //     tapCoreRoomListManager.getUpdatedRoomList({
    //         onSuccess: (roomLists) => {
    //             props.setMainChatDataActionProps(roomLists);

    //             callback !== null && callback.onSuccessGetRoomList(roomLists);
    //         },
    //         onError: (errorCode, errorMessage) => {
    //             console.log(errorCode, errorMessage);
    //         }
    //     });
    // };

    useEffect(() => {
        // let onlineCallbackBotCase = () => {
        // getUpdatedRoomList({
        //     onSuccessGetRoomList: () => {
        //         getBotCaseList(true);
        //     }
        // })
        // }

        // window.addEventListener('online', onlineCallbackBotCase);

        let _topiclist = [];

        //set topic list
        Object.keys(props.topicListProps).map(value => {
            _topiclist.push(props.topicListProps[value]);
            return null;
        });

        setTopicList(_topiclist);
        //set topic list
        // getUpdatedRoomList({
        //     onSuccessGetRoomList: () => {
        //         getBotCaseList(false);
        //     }
        // })
        getAgentList(0);

        if (props.isFetchingRoomListFinishProps) {
            getBotCaseList(INITIAL_OPEN_CASE_PARAM(false));
        }

        // return () => {
        //     window.removeEventListener('online', onlineCallbackBotCase);
        // }
    }, [props.isFetchingRoomListFinishProps]);

    useEffect(() => {
        if (props.newEmitMessageProps !== null) {
            if (isRoomListLoadingFinish) {
                actionUpdateRoomList(props.newEmitMessageProps);
            }
        }
    }, [props.newEmitMessageProps])

    let runNewMessageAction = (mess, callback) => {
        let newMessage = mess;

        if (isRoomListLoadingFinish) {
            actionUpdateRoomList(newMessage);
            callback();
        }
    }

    useEffect(() => {
        if (props.messageListenerNewMessageProps !== null) {
            // let newMessage = props.messageListenerNewMessageProps;
            // // console.log('new', newMessage);

            // if (isRoomListLoadingFinish) {
            //     actionUpdateRoomList(newMessage);
            // }
            let _q = JSON.parse(JSON.stringify(queueEmitMessage));
            props.messageListenerNewMessageProps.timer = new Date().valueOf();

            _q.q.push({
                message: props.messageListenerNewMessageProps,
                type: "new"
            })

            setQueueEmitMessage(_q)
        }
    }, [props.messageListenerNewMessageProps]);

    let runUpdateMessageAction = (mess, callback) => {
        let updateMessage = mess;

        if (isRoomListLoadingFinish) {
            actionUpdateRoomList(updateMessage, true);
            callback();
        }
    }

    useEffect(() => {
        if (props.messageListenerUpdateMessageProps !== null) {
            // let updateMessage = props.messageListenerUpdateMessageProps;
            // // console.log('updet', updateMessage);

            // if (isRoomListLoadingFinish) {
            //     actionUpdateRoomList(updateMessage, true);
            // }
            let _q = JSON.parse(JSON.stringify(queueEmitMessage));

            props.messageListenerUpdateMessageProps.timer = new Date().valueOf();

            _q.q.push({
                message: props.messageListenerUpdateMessageProps,
                type: "update"
            })

            setQueueEmitMessage(_q)
        }
    }, [props.messageListenerUpdateMessageProps])

    // useEffect(() => {
    //     if(Object.keys(otherCaseList).length !== 0) {
    //         setupFilteredRoomList(otherCaseList, 'xcRoomID');
    //     }else {
    //         setChatDataFiltered({});
    //     }
    // }, [otherCaseList])

    useEffect(() => {
        let startTyping = props.listenerStartTypingProps;

        if (startTyping !== null) {
            runActionTypingFromRoomList(startTyping.roomID, 'show', startTyping.user.fullname);

            let _hashMapTypingTimeout = { ...hashMapTypingTimeout };

            if (_hashMapTypingTimeout[startTyping.roomID]) {
                clearTimeout(_hashMapTypingTimeout[startTyping.roomID]);
            }

            _hashMapTypingTimeout[startTyping.roomID] = setTimeout(function () {
                runActionTypingFromRoomList(startTyping.roomID, 'hide');
            }, 10000);

            setHashMapTypingTimeout(_hashMapTypingTimeout);
        }
    }, [props.listenerStartTypingProps])

    useEffect(() => {
        let stopTyping = props.listenerStopTypingProps;

        if (stopTyping !== null) {
            runActionTypingFromRoomList(stopTyping.roomID, 'hide');
            clearTimeout(hashMapTypingTimeout[stopTyping.roomID]);
        }

    }, [props.listenerStopTypingProps])

    useEffect(() => {
        if (isRoomListLoadingFinish) {
            setIsRoomListLoadingFinish(false);
            getBotCaseList({
                topicID: filterTopicValue.value === 0 ? 0 : filterTopicValue.value,
                agentAccountID: filterAgentValue.value === 0 ? 0 : filterAgentValue.value,
                mediums: filterMediumValue,
                search: filterSearchValue,
                lastID: 0,
                isSearchOrFilter: false,
                isReconnect: false,
                isLoadMoreBot: false,
                label: filterLabelValue,
                junkStatus: filterCaseType
            });
        }
    }, [props.caseSortBy.other])

    let runActionTypingFromRoomList = (roomID, action, name) => {
        let targetChatListWrapper = document.querySelector(`.new-case-list-wrapper[data-room-id="${roomID}"]`);

        if (targetChatListWrapper !== null) {
            if (action === 'hide') {
                targetChatListWrapper.classList.remove("active-typing");
            } else {
                targetChatListWrapper.classList.add("active-typing");

                if (targetChatListWrapper.getElementsByClassName("typing-name").length > 0) {
                    targetChatListWrapper.getElementsByClassName("typing-name")[0].innerHTML = name !== "" ? name + ": " : "";
                }
            }
        }
    }

    //setting up new room list
    // let setupFilteredRoomList = (data, filterBaseOn) => {
    //     let _arrayOfMessage = {};
    //     let _arrayOfMessageIsNull = false;

    //     Object.keys(data).map((value) => {    
    //         for(let j in props.mainChatDataProps) {
    //         // for(let j in chatData) {
    //             if(filterBaseOn === 'xcRoomID') {
    //                 if(data[value].tapTalkXCRoomID === props.mainChatDataProps[j].lastMessage.room.xcRoomID) {
    //                     if(!_arrayOfMessageIsNull) {
    //                         _arrayOfMessageIsNull = true;
    //                         _arrayOfMessage = {};
    //                     }

    //                     props.mainChatDataProps[j].caseDetail = data[value];
    //                     _arrayOfMessage[props.mainChatDataProps[j].lastMessage.room.roomID] = props.mainChatDataProps[j];

    //                     break;
    //                 }
    //             }
    //         }
    //     });

    //     setChatDataFiltered(_arrayOfMessage);
    //     setIsRoomListLoadingFinish(true);
    // }
    //setting up new room list

    let getBotCaseList = (data) => {
        // if(data.isReconnect) {
        //   setIsRoomListLoadingFinish(false);
        // }

        if (!data.isReconnect) {
            if (data.lastID === 0 && !data.isSearchOrFilter) {
                setIsRoomListLoadingFinish(false);
            }
        }

        let dataLabel = [];
        if (data.label && data.label.length > 0) {
            dataLabel = data.label.map(item => item.id)
        }

        let _data = {
            topicID: data.topicID,
            agentAccountID: data.agentAccountID,
            assignmentStatus: "chatbot",
            search: data.search ? data.search : "",
            sortBy: props.caseSortBy.other,
            lastID: data.lastID,
            pageSize: 50,
            mediums: data.mediums,
            labelIDs: dataLabel,
            junkStatus: data.junkStatus
        };

        CaseService.postOpenCaseList(props.parentProps.match.params.orgID, _data, (response) => {
            setFetchingBotCase(false);

            if (response.dataResult.error.code === "") {
                if (data.isSearchOrFilter) {
                    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                            "[Action] Search",
                            {
                                tab: "Bots",
                                userFullName: props.myAgentData.account.fullName,
                                userID: props.myAgentData.account.id,
                                organizationID: props.parentProps.match.params.orgID.split("-")[0]
                            }
                        )
                    }
                }

                let resultCase = response.dataResult.data.cases;
                let caseData = {};
                let needReply = { ...needReplyDataBot };

                for (let i in resultCase) {
                    let body = resultCase[i].tapTalkRoom.lastMessage.body;
                    let localID = resultCase[i].tapTalkRoom.lastMessage.localID;
                    let messageData = resultCase[i].tapTalkRoom.lastMessage.data;

                    resultCase[i].tapTalkRoom.lastMessage.body = taptalk.generateBodyAndData(body, localID);

                    if (messageData !== "") {
                        resultCase[i].tapTalkRoom.lastMessage.data = JSON.parse(taptalk.generateBodyAndData(messageData, localID));
                    }

                    caseData[resultCase[i].tapTalkRoom.lastMessage.room.roomID] = resultCase[i];

                    if (resultCase[i].tapTalkRoom.lastMessage.user.userRole.code !== "chatbot" && !resultCase[i].isJunk) {
                        needReply[resultCase[i].tapTalkRoom.lastMessage.room.roomID] = resultCase[i];

                        if (props.needReplyColorIndicator) {
                            needReply[resultCase[i].tapTalkRoom.lastMessage.room.roomID] = HelperChat.needReplyLevelCount(props.needReplyColorIndicator, needReply[resultCase[i].tapTalkRoom.lastMessage.room.roomID]);
                        }
                    }
                }

                setNoMoreBotCase(response.dataResult.data.hasMore);
                setNeedReplyDataBot(needReply);

                if (data.isSearchOrFilter) {
                    if (resultCase.length === 0) {
                        setSearchNotFound(true);
                    }

                    setIsLoadingSearchBotCaseFinish(true);
                    setChatDataFiltered(caseData);
                } else {
                    if (data.isLoadMoreBot) {
                        let currentBotCaseList = { ...chatDataFiltered };

                        currentBotCaseList = Object.assign(currentBotCaseList, caseData);
                        setChatDataFiltered(currentBotCaseList);
                    } else {
                        setChatDataFiltered(caseData);
                    }

                    setIsRoomListLoadingFinish(true);
                }
            } else {
                setIsRoomListLoadingFinish(true);
                setIsLoadingSearchBotCaseFinish(true);
                doToast(response.dataResult.error.message, 'fail');

                if (data.isLoadMoreBot) {
                    setFetchingBotCaseIsError(true);
                }
            }
        })
    }

    let toggleSortByBotCase = () => {
        setIsshowBotCaseSortBy(!isshowBotCaseSortBy);
    }

    let actionSwitchSortBy = (sortByVal) => {
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Action] Change Sorting",
                {
                    sortby: sortByVal === "last_message" ? "Last Message" : "Created Time",
                    tab: "Bots",
                    userFullName: props.myAgentData.account.fullName,
                    userID: props.myAgentData.account.id,
                    organizationID: props.parentProps.match.params.orgID.split("-")[0]
                }
            );
        }

        props.setCaseSortBy({
            unassigned: props.caseSortBy.unassigned,
            mine: props.caseSortBy.mine,
            other: sortByVal
        })
    }

    let submitSearchBotCase = (e) => {
        e.preventDefault();

        if (filterSearchValue !== "") {
            setIsSearchActive(true);

            if (filterSearchValue.length > 0) {
                setIsLoadingSearchBotCaseFinish(false);
            } else {
                setIsRoomListLoadingFinish(false);
            }

            getBotCaseList({
                topicID: filterTopicValue.value === 0 ? 0 : filterTopicValue.value,
                agentAccountID: filterAgentValue.value === 0 ? 0 : filterAgentValue.value,
                mediums: filterMediumValue,
                search: filterSearchValue,
                lastID: 0,
                isSearchOrFilter: true,
                isReconnect: false,
                isLoadMoreBot: false,
                label: filterLabelValue,
                junkStatus: filterCaseType
            });
        }
    }

    let onChangeSearchValue = (e) => {
        if (e.target) {
            setFilterSearchValue(e.target.value);
        }
    }

    // let resetSearchValue = () => {
    //     setFilterSearchValue("");
    //     setIsRoomListLoadingFinish(true);

    //     getBotCaseList({
    //         lastID: 0,
    //         isReconnect: false,
    //         isLoadMoreBot: false,
    //         isSearchOrFilter: true,
    //         search: ""
    //     });
    // }

    let toggleSelectCaseList = (type) => {
        let _caseSelect = { ...props.caseListChecked };
        _caseSelect[type].isShow = !_caseSelect[type].isShow;
        _caseSelect[type].data = {};
        _caseSelect[type].checkedAll = false;

        actionSetPanelCaseBotHeight(_caseSelect[type].isShow);

        props.setCaseListChecked(_caseSelect);
    }

    let toggleModalFilter = () => {
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Button] Filter",
                {
                    userFullName: props.myAgentData.account.fullName,
                    userID: props.myAgentData.account.id,
                    organizationID: props.parentProps.match.params.orgID.split("-")[0]
                }
            );
        }

        //if close
        if (showModalFilterTopicAgent) {
            setFilterTopicValue(filterTopicValueBefore);
            setFilterAgentValue(filterAgentValueBefore);
            setFilterMediumValue(filterMediumValueBefore);
            setFilterCaseType(filterCaseTypeBefore);
            setFilterLabelValue(filterLabelValueBefore);
        }
        //if close

        setShowModalFilterTopicAgent(!showModalFilterTopicAgent);
    }

    let runUpdateFilter = () => {
        setIsLoadingSearchBotCaseFinish(false);

        setFilterTopicValueBefore(filterTopicValue);
        setFilterAgentValueBefore(filterAgentValue);
        setFilterMediumValueBefore(filterMediumValue);
        setFilterLabelValueBefore(filterLabelValue);
        setFilterCaseTypeBefore(filterCaseType);

        getBotCaseList({
            topicID: filterTopicValue.value === 0 ? 0 : filterTopicValue.value,
            agentAccountID: filterAgentValue.value === 0 ? 0 : filterAgentValue.value,
            mediums: filterMediumValue,
            search: filterSearchValue,
            lastID: 0,
            isSearchOrFilter: true,
            isReconnect: false,
            isLoadMoreBot: false,
            label: filterLabelValue,
            junkStatus: filterCaseType
        });
    }

    let toggleIsActiveFilter = (toggleState) => {
        setIsFilterTopicAgentChannelActive(toggleState)
    }

    let toggleResetFilter = () => {
        setIsResetFilter(false);
    }

    let onSelectTopic = (value) => {
        setFilterTopicValue(value)
    }

    let onSelectAgent = (value) => {
        setFilterAgentValue(value)
    }

    let onSelectCaseType = (value) => {
        setFilterCaseType(value);
    }

    const onSelectLabel = (value) => {
        setFilterLabelValue(value)
    }

    let onSelectMedium = (value) => {
        setFilterMediumValue(value);
    }

    let resetFilterAndSearchValue = () => {
        props.clearCaseFilter();
        setIsResetFilter(true);
        // document.getElementById("input-search-form").reset();
        setFilterSearchValue("");

        setIsLoadingSearchBotCaseFinish(true);

        //search
        setSearchNotFound(false);
        setFilterSearchValue('');
        setIsSearchActive(false);
        //search

        //fitler topic and agent
        setFilterTopicValue(DEFAULT_FILTER_TOPIC_AGENT);
        setFilterAgentValue(DEFAULT_FILTER_TOPIC_AGENT);
        setFilterTopicValueBefore(DEFAULT_FILTER_TOPIC_AGENT);
        setFilterAgentValueBefore(DEFAULT_FILTER_TOPIC_AGENT);
        setIsFilterTopicAgentChannelActive(false);
        //fitler topic and agent

        //medium
        setFilterMediumValue([]);
        setFilterMediumValueBefore([]);
        //medium

        //label
        setFilterLabelValue([])
        setFilterLabelValueBefore([])
        //label

        //case type
        setFilterCaseType(CASE_TYPES.allTypes.value)
        setFilterCaseTypeBefore(CASE_TYPES.allTypes.value)
        //case type

        getAgentList(0);
        getBotCaseList(INITIAL_OPEN_CASE_PARAM(false));
    }

    let getAgentList = (topicID) => {
        let data = {
            topicID: topicID
        };

        CaseService.postAgentList(props.parentProps.match.params.orgID, data, (response) => {
            if (response.dataResult.error.code === "") {
                let dataResult = response.dataResult;

                for (let i in dataResult.data.agents) {
                    dataResult.data.agents[i].value = dataResult.data.agents[i].accountID;
                    dataResult.data.agents[i].label = dataResult.data.agents[i].accountName;
                }

                setAgentList(dataResult.data.agents);
            } else {
                doToast(response.dataResult.error.message, 'fail');
            }
        })
    }

    let onChangeNeedReply = (e) => {
        setNeedReplyFilterVal(e.target.checked);
    }

    return (
        <>
            <StartConversation
                {...props}
                isShowModalStartConversationProps={isShowModalStartConversation}
                toggleModalStartConversationProps={toggleModalStartConversation}
                orgIDProps={props.parentProps.match.params.orgID}
                topicListProps={props.topicListProps}
                onClickRoomListProps={props.onClickRoomListProps}
            // toggleSwitchMineCaseProps={toggleSwitchMineCase}
            />

            <div className="bot-case-tab-wrapper main-case-wrapper">
                <div className="bot-case-room-wrapper room-list-chat-container">
                    {/* <Scrollbars autoHide autoHideTimeout={500}
                            renderThumbVertical={props => <div {...props} style={style.scrollStyle} className="room-list-scrollbars" />}
                    > */}
                    <div className="case-room-search-wrapper">
                        <form id="input-search-form" onSubmit={(e) => submitSearchBotCase(e)}>
                            <input type="text"
                                placeholder="Case ID, full name, alias and phone number"
                                onChange={(e) => onChangeSearchValue(e)}
                                value={filterSearchValue}
                            />
                            {/* <FiXCircle className="case-search-close-icon" /> */}

                            <button className="submit-filter-button">
                                <FiSearch />
                            </button>
                        </form>
                    </div>

                    <div className="bot-case-sort-by-wrapper">
                        <div className='bot-case-sort-by-wrapper-inner'>
                            <CaseListSortBy
                                isShowSortByOptionProps={isshowBotCaseSortBy}
                                sortByProps={props.caseSortBy.other}
                                toggleSortByOpenCaseProps={toggleSortByBotCase}
                                actionSwitchSortByProps={actionSwitchSortBy}
                                disabledSortByProps={!isRoomListLoadingFinish || fetchingBotCase}
                            />

                            <div className='sort-by-wrapper-inner-scroll'>
                                {(isSearchActive || isFilterTopicAgentChannelActive) &&
                                    <div className={`case-room-filter-tag-badge case-room-filter-tag-badge-active`} onClick={() => resetFilterAndSearchValue()}>
                                        <FiRotateCcw />
                                        <b>Reset</b>
                                    </div>
                                }

                                <div className={`case-room-filter-tag-badge ${isFilterTopicAgentChannelActive ? 'case-room-filter-tag-badge-active' : ''}`}
                                    onClick={() => toggleModalFilter()}
                                >
                                    <img src={isFilterTopicAgentChannelActive ? iconFilterWhite : iconFilterGrey} alt="" />
                                    <b>Filters</b>
                                    {/* <span className="case-room-filter-tag-counter">2</span> */}
                                </div>

                                {Object.keys(chatDataFiltered).length > 0 &&
                                    <div
                                        className={`
                                            case-room-filter-tag-badge 
                                            ${props.caseListChecked["bot"].isShow ? 'case-room-filter-tag-badge-active' : ''}
                                        `}
                                        onClick={() => toggleSelectCaseList("bot")}
                                    >
                                        <FiList />
                                        <b>Select</b>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {props.caseListChecked["bot"].isShow &&
                        <CaseListAction
                            data={chatDataFiltered}
                            type="bot"
                            orgID={props.parentProps.match.params.orgID}
                            _setLastBulkJunk={setLastBulkJunk}
                            _setLastBulkSolve={setLastBulkSolve}
                            toogleCaseListAction={() => toggleSelectCaseList("bot")}
                            caseLength={Object.keys(chatDataFiltered).length}
                        />
                    }

                    {!isRoomListLoadingFinish ?
                        <div className="room-list-loading-wrapper">
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                            <b>Loading Cases</b>
                        </div>

                        :

                        !isLoadingSearchBotCaseFinish ?
                            <div className="room-list-loading-wrapper">
                                <div className="lds-ring">
                                    <div /><div /><div /><div />
                                </div>
                                <b>Searching for Results</b>
                            </div>
                            :
                            Object.keys(chatDataFiltered).length > 0 ?
                                <div className="room-list-main-chat-container">
                                    <ListBotCaseComponent
                                        case={chatDataFiltered}
                                        caseLabel={props.caseLabelList}
                                        row={printRowBotCaseList}
                                        dataLength={Object.keys(chatDataFiltered).length}
                                        containerHeight={panelBotCaseHeight}
                                        fetchingBotCaseProps={fetchingBotCase}
                                        fetchingBotCaseIsErrorProps={fetchingBotCaseIsError}
                                        onClickRetryMoreCaseBotProps={onClickRetryMoreCase}
                                        onScrollRoomListListenerProps={onScrollRoomListListener}
                                        mainProps={props}
                                        needReplyProps={Object.keys(needReplyDataBot).length}
                                        needReplyData={needReplyDataBot}
                                        isLastCaseHavingMarginBottomProps={props.caseSortBy.other !== SORT_MESSAGE_BY.created_time}
                                        refProps={otherCaseReff}
                                    />

                                    {/* {props.caseSortBy.other === SORT_MESSAGE_BY.last_message &&
                                        (Object.keys(needReplyDataBot).length > 0 &&
                                            <div className="need-reply-unread-panel-wrapper">
                                                <div className="need-reply-unread-panel-content">
                                                    <div className="need-reply-panel">
                                                        <b>Need Reply</b> <div className="need-reply-unread-panel-counter"><b>{Object.keys(needReplyDataBot).length}</b></div>
                                                    </div> */}

                                    {/* <div className="unread-panel">
                                                        <b>Unread</b> <div className="need-reply-unread-panel-counter"><b><b>{totalUnreadCountMine}</b></b></div>
                                                    </div> */}
                                    {/* </div>
                                            </div>
                                        )
                                    } */}
                                </div>
                                :
                                searchNotFound ?
                                    <div className="room-list-no-chat-container">
                                        <b>
                                            No Results Were Found
                                        </b>

                                        {(isFilterTopicAgentChannelActive || isSearchActive) &&
                                            <p>
                                                {FILTER_MESSAGE.filter}
                                            </p>
                                        }

                                        {/* <p>
                                                Please try other filter options, or reset your filter options.
                                            </p> */}

                                        <p className="no-chat-button" onClick={() => resetFilterAndSearchValue()}>
                                            <FiRotateCcw /> <b>Reset Filter Options</b>
                                        </p>
                                    </div>
                                    :
                                    <RoomListNoChat text="It seems like there aren't any other ongoing cases at this very moment." />
                    }
                    {/* </Scrollbars> */}
                </div>

                <FilterBy
                    needReply={needReplyFilterVal}
                    onChangeNeedReply={onChangeNeedReply}
                    filterLabelValueProps={filterLabelValue}
                    onSelectLabelProps={onSelectLabel}
                    filterTopicValueProps={filterTopicValue}
                    filterAgentValueProps={filterAgentValue}
                    filterMediumValueProps={filterMediumValue}
                    filterCaseTypeProps={filterCaseType}
                    filterCaseTypeBeforeProps={filterCaseTypeBefore}
                    isOpenProps={showModalFilterTopicAgent}
                    updateFilterProps={runUpdateFilter}
                    closeModal={() => setShowModalFilterTopicAgent(false)}
                    toggleProps={toggleModalFilter}
                    agentListProps={agentList}
                    topicListProps={topicList}
                    toggleIsActiveFilterProps={toggleIsActiveFilter}
                    isResetFilterProps={isResetFilter}
                    toggleResetFilterProps={toggleResetFilter}
                    onSelectTopicProps={onSelectTopic}
                    onSelectAgentProps={onSelectAgent}
                    onSelectMediumProps={onSelectMedium}
                    onSelectCaseType={onSelectCaseType}
                    isHideAgentProps={true}
                    orgID={props.parentProps.match.params.orgID}
                    room="bot"
                />
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    updateCaseLabel: state.updateCaseLabel,
    activeCasePanel: state.activeCasePanel,
    changeContactInfo: state.changeContactInfo,
    caseSortBy: state.caseSortBy,
    caseLabelList: state.caseLabelList,
    draftMessage: state.draftMessage,
    caseListChecked: state.caseListChecked,
    reconnectRoomList: state.reconnectRoomList,
    needReplyColorIndicator: state.needReplyColorIndicator,
    caseFilter: state.caseFilter,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    clearUpdateCaseLabel,
    clearChangeContactInfo,
    setCaseSortBy,
    clearStartConversation,
    clearStartConversationBa,
    setActiveRoom,
    setCaseListChecked,
    setReconnectRoomList,
    clearCaseFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(BotCaseTab);